<template>
  <div class="secretariat">
    <!-- Sidebar -->
    <div class="menu">
      <div class="pt-1 pe-4 ps-4">
        <v-btn
          @click="openNewLetter"
          elevation="0"
          color="primary newLetterButton"
          class="w-100"
          rounded
          height="45"
          dir="rtl"
          >نامه جدید <v-icon class="ps-4">$PenSquare</v-icon></v-btn
        >
      </div>
      <div class="menu-box ps-2 pe-2 mt-3 pb-4">
        <div class="menu-items pa-0" v-for="(item, index) in nav" :key="index">
          <router-link
            v-if="item.name == 'صندوق ورودی'"
            style="text-decoration: none"
            :to="'/' + role + item.url"
          >
            <div
              class="py-1 ps-3 pe-3 d-flex flex-row justify-between-space"
              :class="
                $router.currentRoute.path.includes(item.url) ? 'active' : ''
              "
            >
              <div
                v-if="messagesNumber.inboxLettersNotSeenCount"
                class="menu-badge"
              >
                {{ messagesNumber.inboxLettersNotSeenCount }}
              </div>
              <div class="d-flex justify-content-end align-items-center my-0">
                <h6>{{ item.name }}</h6>
                <v-icon class="pe-4">{{ item.icon }}</v-icon>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="item.name == 'صندوق سازمانی'"
            style="text-decoration: none"
            :to="'/' + role + item.url"
          >
            <div
              class="py-1 ps-3 pe-3 d-flex flex-row justify-between-space"
              :class="
                $router.currentRoute.path.includes(item.url) ? 'active' : ''
              "
            >
              <div
                v-if="messagesNumber.internalInboxLettersNotSeenCount"
                class="menu-badge"
              >
                {{ messagesNumber.internalInboxLettersNotSeenCount }}
              </div>
              <div class="d-flex justify-content-end align-items-center my-0">
                <h6>{{ item.name }}</h6>
                <v-icon class="pe-4">{{ item.icon }}</v-icon>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="item.name == 'در انتظار امضا'"
            style="text-decoration: none"
            :to="'/' + role + item.url"
          >
            <div
              class="py-1 ps-3 pe-3 d-flex flex-row justify-between-space"
              :class="
                $router.currentRoute.path.includes(item.url) ? 'active' : ''
              "
            >
              <div
                v-if="messagesNumber.waitingForSignLettersCount"
                class="menu-badge"
              >
                {{ messagesNumber.waitingForSignLettersCount }}
              </div>
              <div class="d-flex justify-content-end align-items-center my-0">
                <h6>{{ item.name }}</h6>
                <v-icon class="pe-4">{{ item.icon }}</v-icon>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="item.name == 'تخصیص به من'"
            style="text-decoration: none"
            :to="'/' + role + item.url"
          >
            <div
              class="py-1 ps-3 pe-3 d-flex flex-row justify-between-space"
              :class="
                $router.currentRoute.path.includes(item.url) ? 'active' : ''
              "
            >
              <div
                v-if="messagesNumber.assigneLettersLettersCount"
                class="menu-badge"
              >
                {{ messagesNumber.assigneLettersLettersCount }}
              </div>
              <div class="d-flex justify-content-end align-items-center my-0">
                <h6>{{ item.name }}</h6>
                <v-icon class="pe-4">{{ item.icon }}</v-icon>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="item.name == 'یادآوری پیگیری'"
            style="text-decoration: none"
            :to="'/' + role + item.url"
          >
            <div
              class="py-1 ps-3 pe-3 d-flex flex-row justify-between-space"
              :class="
                $router.currentRoute.path.includes(item.url) ? 'active' : ''
              "
            >
              <div
                v-if="messagesNumber.pendingFollowingsLettersCount"
                class="menu-badge"
              >
                {{ messagesNumber.pendingFollowingsLettersCount }}
              </div>
              <div class="d-flex justify-content-end align-items-center my-0">
                <h6>{{ item.name }}</h6>
                <v-icon class="pe-4">{{ item.icon }}</v-icon>
              </div>
            </div>
          </router-link>
          <router-link
            v-if="
              item.name != 'صندوق ورودی' &&
              item.name != 'صندوق سازمانی' &&
              item.name != 'تخصیص به من' &&
              item.name != 'در انتظار امضا' &&
              item.name != 'یادآوری پیگیری'
            "
            style="text-decoration: none"
            :to="'/' + role + item.url"
          >
            <div
              class="py-1 ps-3 pe-3 d-flex justify-content-end align-items-center"
              :class="
                $router.currentRoute.path.includes(item.url) ? 'active' : ''
              "
            >
              <h6>{{ item.name }}</h6>
              <v-icon class="pe-4">{{ item.icon }}</v-icon>
            </div>
          </router-link>
        </div>
        <div
          class="menu-items pa-0"
          v-if="role == 'admin' || role == 'reception'"
        >
          <router-link
            style="text-decoration: none"
            :to="'/' + role + '/secretariat/contacts'"
          >
            <div
              class="py-1 ps-3 pe-3 d-flex flex-row justify-between-space"
              :class="
                $router.currentRoute.path.includes('/secretariat/contacts')
                  ? 'active'
                  : ''
              "
            >
              <div class="d-flex justify-content-end align-items-center my-0">
                <h6>مدیریت مخاطبین</h6>
                <v-icon class="pe-4">$Customer</v-icon>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- page header-->
    <div class="page-header">
      <div class="me-6">
        <v-icon @click="openSetting()" style="font-size: 24px !important"
          >mdi-cog</v-icon
        >
      </div>
      <div class="me-6">
        <v-icon>$Letters</v-icon>
        <div v-if="messagesNumber.notificationCount" class="letter-badge">
          {{ messagesNumber.notificationCount }}
        </div>
      </div>
      <!-- <v-menu v-model="messagesMenu" offset-y bottom>
        <template v-slot:activator="{ on, attrs }">
          <button class="ms-4 me-4" v-bind="attrs" v-on="on">
            <v-icon>$Alarm</v-icon>
          </button>
        </template>
        <v-card
          class="menu-card pa-5 grey--text text--darken-2"
          style="min-width: 500px"
        >
          <v-row>
            <v-col>
              <p class="pt-3" style="font-size: 16px">
                شما {{ messages.length }} نامه جدید دارید
              </p>
            </v-col>
            <v-col class="text-left">
              <v-icon @click="messagesMenu = false" dense color="red"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
          <div v-if="!messages.length" class="text-center">
            <hr />
            موردی برای نمایش وجود ندارد.
            <hr />
          </div>
          <div v-else class="messages">
            <div
              v-for="(item, index) in messages"
              :key="index"
              class="pt-4 me-4 pointer"
              @click="goToLetter(item.id)"
            >
              <div class="d-flex flex-row justify-space-between">
                <div class="d-flex flex-row">
                  <div class="image-box">
                    <v-img :src="item.avatar"></v-img>
                  </div>
                  <span style="font-size: 1.03rem" class="pa-5">{{
                    item.name
                  }}</span>
                </div>
                <div class="pt-6">
                  {{ item.date }}
                </div>
              </div>
              <hr v-if="index != messages.length - 1" class="mb-0" />
            </div>
          </div>
          <div class="text-center mt-4">
            <v-btn color="primary" text>
              <span color="primary">نامه‌های دریافت شده</span>
              <v-icon class="px-2">mdi-chevron-left</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-menu> -->
    </div>
    <!--main-->
    <div class="mainSecretariat">
      <main>
        <div class="container-fluid pe-5 ps-5">
          <router-view></router-view>
        </div>
      </main>
    </div>

    <v-dialog v-model="newLetterDialog" width="600px">
      <v-card class="modal-card">
        <v-row>
          <v-col>
            <h5 class="pt-4">نوع نامه مورد نظر را انتخاب کنید</h5>
          </v-col>
          <v-col class="text-left">
            <span @click="newLetterDialog = false" class="pointer">
              <v-icon dense color="red">mdi-close</v-icon>
            </span>
          </v-col>
        </v-row>
        <div class="newLetter-radioBtn">
          <v-radio-group v-model="letterType">
            <v-radio
              v-for="(item, index) in letterTypes"
              :key="index"
              :label="item.label"
              :value="item.value"
              class="mb-4"
            ></v-radio>
          </v-radio-group>
        </div>
        <v-row>
          <v-col>
            <v-btn @click="goToNew()" class="primary-btn" width="120"
              >تایید</v-btn
            >
          </v-col>
          <v-col class="text-left">
            <v-btn
              @click="newLetterDialog = false"
              class="red-borderedBtn"
              width="120"
              >بازگشت</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editLetterNoModal" width="600px">
      <v-card class="modal-card">
        <v-row>
          <v-col>
            <h5 class="pt-4 fs-medium">
              در این بخش میتوانید آخرین شماره نامه ارسال شده را ویرایش کنید
            </h5>
          </v-col>
          <v-col cols="1" class="text-left">
            <span @click="editLetterNoModal = false" class="pointer">
              <v-icon dense color="red">mdi-close</v-icon>
            </span>
          </v-col>
        </v-row>
        <div>
          <v-text-field
            outlined
            dense
            v-model="currentIndex"
            label="آخرین شماره نامه"
            class="mt-8"
          >
          </v-text-field>
        </div>
        <v-row>
          <v-col>
            <v-btn @click="submitSetting()" class="blue-mainBtn" width="120"
              >تایید</v-btn
            >
          </v-col>
          <v-col class="text-left">
            <v-btn
              @click="editLetterNoModal = false"
              class="purple-borderedBtn"
              width="120"
              >بازگشت</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  data() {
    return {
      role: "",
      nav: [],
      messagesNumber: {
        notificationCount: 0,
        inboxLettersNotSeenCount: 0,
        internalInboxLettersNotSeenCount: 0,
        assigneLettersLettersCount: 0,
        waitingForSignLettersCount: 0,
        pendingFollowingsLettersCount: 0,
      },
      newLetterDialog: false,
      letterType: "",
      letterTypes: [
        {
          label: "سازمانی",
          value: "internal",
        },
        {
          label: "ورودی",
          value: "inbox",
        },
        {
          label: "خروجی",
          value: "outbox",
        },
      ],
      editLetterNoModal: false,
      currentIndex: 0,
      messagesMenu: false,
      role: window.localStorage.role,
    };
  },
  methods: {
    openSetting() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/options/getInfo",
        {
          key: "letterIndex",
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.currentIndex = res.data;
            this.editLetterNoModal = true;
          }
        })
        .catch((err) => {
          this.toast(err, "error");
        });
    },
    submitSetting() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/options/edit",
        {
          key: "letterIndex",
          value: this.currentIndex,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.editLetterNoModal = false;
            this.toast(res.data, "success");
          } else {
            this.toast(res.data, "error");
          }
        })
        .catch((err) => {
          this.toast(err, "error");
        });
    },
    openNewLetter() {
      this.letterType = "internal";
      this.newLetterDialog = true;
    },
    goToNew() {
      this.$router.push(
        "/" + this.role + "/secretariat/" + this.letterType + "/newLetter"
      );
      this.newLetterDialog = false;
    },
    goToLetter(id) {
      this.$router.push("/" + this.role + "/secretariat/inbox/letter/" + id);
    },
    getNewMessages() {
      Axios.post(
        this.baseUrl + "/clinic/requirements/checkToken",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.messagesNumber.notificationCount = res.data.notificationCount;
            this.messagesNumber.inboxLettersNotSeenCount =
              res.data.inboxLettersNotSeenCount;
            this.messagesNumber.internalInboxLettersNotSeenCount =
              res.data.internalInboxLettersNotSeenCount;
            this.messagesNumber.assigneLettersLettersCount =
              res.data.assigneLettersLettersCount;
            this.messagesNumber.waitingForSignLettersCount =
              res.data.waitingForSignLettersCount;
            this.messagesNumber.pendingFollowingsLettersCount =
              res.data.pendingFollowingsLettersCount;
          }
        })
        .catch((err) => {
          if (err.response.status == 403 || err.response.status == 404) {
            const title = (
              <div>
                <h3 class="text-center">دسترسی منقضی شده است</h3>
                <span>لطفا جهت استفاده از سامانه، دوباره ورود کنید</span>
              </div>
            );
            this.toast(title, "error");
            this.$router.push({
              path: "/login",
              query: { return: window.location.pathname },
            });
          }
        });
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.getNewMessages();
    this.nav = this.$store.getters.nav.secretariat;
    if (
      !document.body.classList.contains("sidebar-minimized") &&
      !document.body.classList.contains("brand-minimized")
    ) {
      document.body.classList.add("sidebar-minimized");
      document.body.classList.add("brand-minimized");
      // this.toast(
      //   "در صورت نیاز به منو، میتوانید از علامت موجود در سمت راست صفحه استفاده کنید"
      // );
    }
  },
};
</script>
<style lang="scss">
.secretariat {
  position: relative;
  .v-card {
    border-radius: 12px;
    box-shadow: none !important;
    padding: 16px;
  }
  .menu {
    position: fixed;
    top: 63px;
    left: 0px;
    width: 240px;
    height: calc(100vh - 70px);
    border-right: 1px solid #adb1c25f;
    background-color: #ffffff;
    padding: 15px 0;
    z-index: 1;
    direction: ltr;

    .newLetterButton:hover span {
      color: #fff;
    }
    .menu-badge {
      height: 24px;
      min-width: 24px;
      max-width: fit-content;
      display: flex;
      padding: 4px 6px;
      justify-content: center;
      background-color: #4c81c9;
      border-radius: 50px;
      color: #fff;
      margin: 8px 0 0 0;
      font-size: 12px;

      &:hover {
        background-color: #4c81c9;
        color: #fff;
      }
    }

    .logo {
      width: 36px;
      height: 36px;
      margin: auto;
    }
    &-box {
      max-height: calc(100vh - 190px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: #eee;
        opacity: 0.5;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d4d4d4;
        width: 10px;
        border-radius: 10px;
      }
    }
    &-items {
      max-height: 250px;
      div {
        width: 100%;
        display: flex;
        justify-content: start;
        margin: 10px 0;
        cursor: pointer;
        border-radius: 12px;
        color: #5f6581;
        path {
          stroke: #5f6581;
        }

        &.active {
          background-color: #e7f2fe;
          color: #4c81c9;
          path {
            stroke: #4c81c9;
          }
        }
        &:hover {
          background-color: #e7f2fe;
          color: #4c81c9;
          path {
            stroke: #4c81c9;
          }
        }
      }
    }
  }
  .page-header {
    position: fixed;
    right: 0;
    top: 63px;
    z-index: 30;
    width: calc(100% - 240px);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(128, 128, 128, 0.348);
    .v-icon {
      position: relative;
    }
    path {
      stroke: #4c81c9;
    }
    .letter-badge {
      position: absolute;
      top: 4px;
      left: 37px;
      background-color: #e22121;
      color: #fff;
      border-radius: 50px;
      min-width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 3px 6px;
      font-size: 12px;
    }
  }
  .mainSecretariat {
    margin-top: 150px;
    width: calc(100% - 245px);
  }
  @media only screen and (max-width: 1000px) {
    .page-header {
      width: calc(100% - 241px);
    }
  }
}
.messages {
  .image-box {
    width: 68px;
    height: 68px;
    .v-image {
      width: 100%;
      height: 100%;
      border-radius: 50px;
    }
  }
  max-height: 390px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
    opacity: 0.5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
    width: 10px;
    border-radius: 10px;
  }
}
.modal-card {
  padding: 20px !important;
  overflow: hidden;
  border-radius: 12px !important;
  .image-box {
    height: 550px;
    pointer-events: none;
  }
  .newLetter-radioBtn {
    .v-label {
      margin: 7px;
      color: #4e536a;
    }
  }
}
</style>
